const ScootStatusToKorean = {
  Cargo: "창고",
  Ready: "배치됨",
  Reallocated: "재배치됨",
  WaitingForCapture: "수거대상",
  Captured: "수거됨",
  CapturedForReallocating: "재배치수거됨",
  Missing: "미수거",
  MissingTwo: "견인처리대상",
  MissingThree: "민원처리대상",
  Lost: "분실",
  Exception: "수거불필요",
  Disuse: "IOT 폐기",
  Report: "신고됨",
  WaitingForReallocate: "재배치대상",
  CargoForReady: "출고됨",
  WaitingForChangeBattery: "배터리 교체대상",
};

//TODO: 밸류가 안보여요 ㅠㅠ 예상
const CouponValueToKorean = {
  S: "킥보드",
  B: "자전거",
  M: "모패드",
  SB: "킥보드, 자전거",
  SM: "킥보드, 자전거, 모패드",
  BM: "자전거, 모패드",
  SBM: "킥보드, 자전거, 모패드",
  T: "택시",
}

const ScootStatusToKoreanForAntd = [
  {
    text: "창고",
    value: "Cargo",
  },
  {
    text: "배치됨",
    value: "Ready",
  },
  {
    text: "재배치됨",
    value: "Reallocated",
  },
  {
    text: "수거대상",
    value: "WaitingForCapture",
  },
  {
    text: "수거됨",
    value: "Captured",
  },
  {
    text: "재배치수거됨",
    value: "CapturedForReallocating",
  },
  {
    text: "미수거",
    value: "Missing",
  },
  {
    text: "견인처리대상",
    value: "MissingTwo",
  },
  {
    text: "민원처리대상",
    value: "MissingThree",
  },
  {
    text: "분실",
    value: "Lost",
  },
  {
    text: "수거불필요",
    value: "Exception",
  },
  {
    text: "폐기",
    value: "Disuse",
  },
  {
    text: "신고됨",
    value: "Report",
  },
  {
    text: "재배치대상",
    value: "WaitingForReallocate",
  },
  {
    text: "출고됨",
    value: "CargoForReady",
  },
  {
    text: "배터리 교체대상",
    value: "WaitingForChangeBattery",
  },
]

const BelongingIdToKoreanForAntd = [
  {
    text: "직영기기",
    value: 1,
  },
  {
    text: "AJ 기기",
    value: 2,
  },
  {
    text: "BS렌탈 기기",
    value: 3,
  },
  {
    text: "M캐피탈 기기",
    value: 4,
  },
  {
    text: "한국맥쿼리 기기",
    value: 5,
  },
  {
    text: "BNK 기기",
    value: 6,
  },
  {
    text: "카123 기기",
    value: 7,
  }
];

const HistroyTypeToKorean = {
  ...ScootStatusToKorean,
  Fixed: "수리완료",
  Broken: "고장",
  Normal: "일반",
  Allocated: "배치위치",
  Riding: "운행시작",
  EndRiding: "운행종료",
  Available: "이용가능",
  Disable: "이용불가",
  Attached: "배터리 장착됨",
  Detached: "배터리 탈착됨",
  //얘는 대체 뭐임
  CapturedForChangeBattery: "배터리 교체"
};

const BelongingIdToKorean = {
  1: "직영기기",
  2: "AJ 기기",
  3: "BS렌탈 기기",
  4: "M캐피탈 기기",
  5: "한국맥쿼리 기기",
  6: "BNK 기기",
  7: "카123 기기",
};

const VehicleTypeToKorean = {
  SCOOTER: "킥보드",
  BIKE: "자전거",
  MOPED: "모패드",
};

const BatteryStatusToKorean = {
  Cargo: "입고됨",
  Attached: "장착됨",
  Detached: "탈착됨",
  Captured: "탈착 및 수거됨",
  WaitingForCapture: "교체대상",
  Lost: "분실",
  Disuse: "폐기",
  Exception: "교체불필요",
  Unknown: "상태미상"
}

const bankEnum = {
  "001": "한국은행",
  "002": "산업은행",
  "003": "기업은행",
  "004": "국민은행",
  "007": "수협은행",
  "008": "수출입은행",
  "011": "농협은행",
  "012": "지역 농 축협",
  "020": "우리은행",
  "023": "SC제일은행",
  "027": "한국씨티은행",
  "031": "대구은행",
  "032": "부산은행",
  "034": "광주은행",
  "035": "제주은행",
  "037": "전북은행",
  "039": "경남은행",
  "045": "새마을금고중앙회",
  "048": "신협",
  "050": "저축은행",
  "051": "기타 외국계은행",
  "052": "모건스탠리은행",
  "054": "HSBC은행",
  "055": "도이치은행",
  "057": "JP모간체이스",
  "058": "미즈호은행",
  "059": "엠유에프지은행",
  "060": "BOA은행",
  "061": "비엔피파리은행",
  "062": "중국공상은행",
  "063": "중국은행",
  "064": "산림조합중앙회",
  "065": "대화은행",
  "066": "교통은행",
  "067": "중국건설은행",
  "071": "우체국",
  "076": "신용보증기금",
  "077": "기술보증기금",
  "081": "하나은행",
  "088": "신한은행",
  "089": "케이뱅크",
  "090": "카카오뱅크",
  "091": "국세청",
  "093": "한국주택금융공사",
  "094": "서울보증보험",
  "095": "경찰청",
  "096": "한국전자금융㈜",
  "099": "금융결제원",
  "101": "한국신용정보원",
  "102": "대신저축은행",
  "103": "에스비아이저축은행",
  "104": "에이치케이저축은행",
  "105": "웰컴저축은행",
  "209": "유안타증권",
  "218": "KB증권",
  "221": "골든브릿지투자증권",
  "222": "한양증권",
  "223": "리딩투자증권",
  "224": "BNK투자증권",
  "225": "IBK투자증권",
  "227": "KTB투자증권",
  "238": "미래에셋대우",
  "240": "삼성증권",
  "243": "한국투자증권",
  "247": "NH투자증권",
  "261": "교보증권",
  "262": "하이투자증권",
  "263": "현대차증권",
  "264": "키움증권",
  "265": "이베스트투자증권",
  "266": "SK증권",
  "267": "대신증권",
  "269": "한화투자증권",
  "270": "하나금융투자",
  "278": "신한금융투자",
  "279": "DB금융투자",
  "280": "유진투자증권",
  "287": "메리츠종합금융증권",
  "289": "NH투자증권",
  "290": "부국증권",
  "291": "신영증권",
  "292": "케이프투자증권",
  "293": "한국증권금융",
  "294": "펀드온라인코리아",
  "295": "우리종합금융",
  "299": "아주캐피탈"
};

export {
  ScootStatusToKorean,
  CouponValueToKorean,
  ScootStatusToKoreanForAntd,
  BelongingIdToKoreanForAntd,
  HistroyTypeToKorean,
  BelongingIdToKorean,
  BatteryStatusToKorean,
  bankEnum,
  VehicleTypeToKorean
};

